import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Userstorage } from 'src/app/billeterie/model/entity';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currentUserstorageSubject: BehaviorSubject<Userstorage>;
  public currentUserstorage: Observable<Userstorage>;

  constructor(private http: HttpClient, handler: HttpBackend) {
    this.http = new HttpClient(handler);
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  public get currentUserstorageValue(): Userstorage {
    return this.currentUserstorageSubject.value;
  }



  public loginUser(value: any): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.apiUrl}/session/org/login`, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }



  get(path: string): Observable<HttpResponse<any>> {
    return this.http.get(environment.apiUrl + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
    });
  }

  sendFileToStorage(file: File, code: string, path: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    console.log(file);
    console.log(formdata);
    console.log(formdata.get('file'));
    //    const req = new HttpRequest('POST', URL_APP_CONFIG + "document/upload?document=" + document + "&service=" + service + "&souscription=" + souscription, formdata, {
    const req = new HttpRequest('POST', `${environment.apiUrl}` + path + "?code=" + code, formdata, {
      reportProgress: true,
      responseType: 'text'
    } ,
    );
    return this.http.request(req);
  }

  public post(value: any, url): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.apiUrl}/` + url, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate`, {
        username,
        password
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem(`${environment.keylocalstorage}`, JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(`${environment.keylocalstorage}`);
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
