export const environment = {
  production: true,
  //apiUrl: 'http://51.75.200.156:9213',

//API BACK END URL

// apiUrl:'http://51.91.99.88:8080/BilleterieDigitaleBO-v-1.0.0',

  //apiUrl:'http://nbh.ci:8025/digitalbackendorganisateur/',

//Anicien qui marchait
  //apiUrl:'https://tickey.ci/BilleterieDigitaleBO-v-1.0.0',

  //Ca marche bien
  //apiUrl:'http://51.91.99.88:8070/BilleterieDigitaleBO-v-1.0.0',

  //apiUrl:'http://57.129.6.156:8080/digitalbackendorganisateur',
  //  apiUrl:'http://57.129.6.156/api/digitalbackendorganisateur',
  //apiUrl:' https://nbh-organisateur.com/digitalbackendorganisateur',
  apiUrl:'https://myenyo.net/digitalbackendorganisateur',
  // apiUrl:' https://myenyo.net/digitalbackendorganisateur',

  //apiUrl: 'http://nbh.ci:4848/digitalbackendorganisateur',

// End Link production

//apiUrl:'http://tickey.ci:8070/BilleterieDigitaleBO-v-1.0.0/',

  // apiUrl:'http://localhost:9275',
  state: 'local',
  serverLoggingUrl: '',
  keylocalstorage: 'curentadmin-billeterie',
  typeuserstatus_admin: '#tprcd-$',
  typeuserstatus_org: '#tpocd_@',
  typeuserstatus_ctrl: '#tpctrl-$',

  SUCCES_MESSAGE_CREATE_APP_CONFIG: "Bienvenue sur NBH CI. Votre compte a été crée avec succès, veuillez entrer votre email et votre mot de passe pour vous connecter ! votre email est  ",

  entreprise_root_code: '#uercd-$',
  entreprise_particular_code: 'SHNUTW19',
  entreprise_org_code: 'UDWJWR20',


  typeusercode_org: 'BNKKLRL6',
  typeusercode_com: 'WCSOTFJ7',
  typeusercode_ctrl: 'TZCHMEV8',
  typeusercode_admin: 'UWBEWTS1',


  typeusercode_pvc: 'IFB249',
  typeusercode_pvt: 'RMU250',
  typeusercode_pvtc: 'MTK251',


  typeusercode_comc: 'HSO252',
  typeusercode_comt: 'GPM253',
  typeusercode_comtc: 'IMX254',


  event_status_waiting_code: 'VSJDS4',
  event_status_enable_code: 'PICLE5',
  event_status_disable_code: 'PINOO6',

  event_state_waiting_code: 'AZSGN7',
  event_state_runing_code: 'YANVH8',
  event_state_report_code: 'XCGVU9',
  event_state_disable_code: 'JDCB10',
  event_state_end_code: 'IAMU11',

  event_image_principale_code: 'IQVD31',
  event_image_secondaire_code: 'RIDR32',
  event_image_publicitaire_code: 'HEQC33',

  typeplacecode_numerote: 'NJJRXU47',
  typeplacecode_nonumerote: 'RRZFAD48',


  PROMOTION_CODE: 'YRDONW39',
  PROMOTION_QTE: 'FFBJYJ38',

  REDUCTION_POURCENTAGE: 'OGNUEY37',
  REDUCTION_MONTANT: 'FUPJTM36',


  CARTE_CREER: 'QZHRJZADLV68',
  CARTE_LIER: 'RLMWNXDHZQ69',
  CARTE_ATTRIBUER: 'HLACZQKBCE70',
  CARTE_ACTIF: 'HDTNXDQSZN71',
  CARTE_INACTIF: 'JHTWHMCOEC72',
  CARTE_EXPIRER: 'GZIMUO74',
  CARTE_VENDU: 'XBOBKY73',


  keyNormal: 'RZHWMF35',
  keyShared: 'CHNQVO36',
  keyCarte: 'QFECC112',
  keyQrcode: 'KCQHZ113',
  categorieTicketPayant: 'NKMXCF21',
  categorieTicketGratuit: 'IHPHWW23',
  categorieTicketLibre: 'DTCJET22',


  notifTypeNotifCode: 'HAH281',
  notifTypeSmsCode: 'RRF280',

  notifObjetSms1: 'JXR282',
  notifObjetSms2: 'QTG283',
  notifObjetSms3: 'JED284',
  notifObjetSms4: 'TNA285',
  notifObjetSms5: 'RSB286'


};

//  apiUrl: 'http://vmi359686.contaboserver.net:9213',
