export class Entity {
}

export class Renewalpassword {

    public id: String;
    public oldpasswd: String;
    public newpasswd: String;
    public keyword: String;

}

export class Entreprise {
    entreprisecategorie: string;
    entreprisecodecategorie: string;
    entreprisecontact: string;
    entreprisedescription: string;
    entrepriseid: string;
    entreprisemail: string;
    entreprisenom: string;
    entreprisereference: string;
    entreprisesecteur: string;
    entreprisesiege: string;
}



export class Typeuser {
    lockFlag: number;
    typeuserid: string;
    typeuserlibele: string;
    typeuserstatus: string;
    typeuserenable: number;
}


export class User {
    lockFlag: number;
    utilisateurid: string;
    utilisateuremail: string;
    utilisateurpassword: string;
    utilisateurstatus: string;
    utilisateurenable: number;
    utilisateurnom: string;
    utilisateurprenom: string;
    utilisateururl: string;
    entreprise: Entreprise;
    typeuser: Typeuser;
}

export class Userstorage {
    utilisateurcode: string;
    utilisateuremail: string;
    utilisateurname: string;
    utilisateurlastname: string;
    utilisateurphoto: string;
    typeusercode: string;
    organecode: string;
    utilisateurtel: string;
    utilisateurpays: string;
    utilisateurville: string;
    utilisateurcommune: string;
    organeraisonsociale: string;
    token: string;
    isadmin: boolean;
}

export class Userdto {
    entreprise: Entreprise;
    utilisateurcommune: string;
    utilisateurdate: Date;
    utilisateurdoc1: string;
    utilisateurdoc2: string;
    utilisateurdoc3: string;
    utilisateuremail: string;
    utilisateurid: string;
    utilisateurnom: string;
    utilisateurorganisation: string;
    utilisateurorganisationcheck: boolean;
    utilisateurpassword: string;
    utilisateurprenom: string;
    utilisateurprofession: string;
    utilisateurrib: string;
    utilisateurstatus: string;
    utilisateurtypeuser: string;
    codecategorie: string;
}

export class Evenement {
    eventid: string;
    eventtitre: string;
    eventdescbrev: string;
    eventdescdet: string;
    eventinvite: string;
    evententreprise: string;

    eventorganisateur: string;
    eventpartenaire: string;

    eventcat: string;
    eventtotalpart: number;
    eventmaxqtetick: number;
    eventunqtetick: number;
    eventperiode: string;
    eventduree: string;
    eventdebut: any;
    eventfin: any;
    eventdebutvente: any;
    eventfinvente: any;

    evententref: string;
    eventcatcode: string;
    eventypecode: string;
    eventype: string;
    eventstatus: string;
    eventetat: string;
    eventdate: any;

    eventdebutcheck: string;
    eventfincheck: string;
    eventdisablevent: string;

    eventdatedebutcheckticket: any;
    eventdatefincheckticket: any;
    eventdatedisablevent: any;

}
export class Eventcat {
    eventcatid: string;
    eventcatlib: string;
    eventcatcode: string;
    eventcatdate: any;
}


export class Typevent {
    typeeventid: string;
    typeeventlibele: string;
    typeeventref: string;
    typeeventstatus: string;
}
export class StateDto {
    by: string;
    focus: number;
    ref: string;
    reflib: string;
    status: string;
}

export class Typeimg {
    typeimageid: string;
    typeimagelib: string;
    typeimageref: string;
}

export class Image {
    imageid: string;
    imagetyperef: string;
    imagelink: string;
    event: Event;
    typeimg: Typeimg;
    imgshowenable: any;
}
export class Caticket {
    caticketid: string;
    caticketdesc: string;
    caticketlib: string;
    catickettyplace: string;
    caticketcodtyplace: string;
    caticketentreprise: string;
    caticketentreprisecode: string;
    caticketevent: string;
    caticketeventcode: string;
    caticketnbre: number = 1;
    catickettarif: number;
    caticketqte: number;
    caticketref: string;
    event: any;
}

export class Cartrequest {
    event: any;
    codepromo: string;
    type: string;
    caticket: Caticket;
    push: Push[];
    montant: any;
    spectateurid: string;

    ticketvendeur: string;
    ticketvendeurtype: string;
    ticketvendeurid: string;



}

export class Push {
    nom: string;
    prenom: string;
    carte: string;
    tel: string;
    email: string;
}

export class Place {
    placeid: string;
    placelib: string;
    placecode: string;
}

export class Promo {
    promocaticket: string;
    promocaticketcode: string;
    promocodetypred: string;
    promodesc: string;
    promoenable: number;
    promoent: string;
    promoentcode: string;
    promoevent: string;
    promoeventcode: string;
    promoid: string;
    promomontantred: number;
    promopoured: number;
    promoref: string;
    promostatus: string;
    promotypepromo: string;
    promotypepromocod: string;
    promotypred: string;
    promovaleurcode: string;
    promovaleurqtered: number;
}


export class Typepromo {
    typepromoid: string;
    typepromolib: string;
    typepromocode: string;
}


export class Typered {
    typredid: string;
    typredlib: string;
    typredcode: string;
}


export class Groupedto {
    groupeid: string;
    groupecode: number;
    groupedesaction: any;
    groupelib: string;
    groupedatecreation: string;
}


export class Cartedto {
    carteref: string;
    cartestatus: number;
    carteagentcreation: string;
    cartedateexpiration: string;
    carteprice: string;
    carteid: string;
}


export class Carte {
    carteid: string;
    carteref: string;
    cartecode: string;
    cartedatecreation: any;
    cartedatemiseactivite: any;
    cartedateexpiration: any;
    cartedatelastblocage: any;
    cartedatelastdeblocage: any;

    carteagentcreation: string;
    carteagentblocage: string;
    carteargentdebloquage: string;
    cartespectateurid: string;

    carteprice: string;
    cartespectateuremail: string;
    cartespectateurnom: string;
    cartespectateurprenom: string;
    cartespectateurtel: string;
    cartestatus: string;
    cartecategorie: any;
    groupelib: any;
    groupedesaction: any;
    cartebloquagemotif: any;
}

export class StatSpecifiqDto {
    event: Evenement;
    caticket: Caticket;
    transactionnbreall: number = 0;
    transactionsumall: number = 0;
    transactionnbrenow: number = 0;
    transactionsumnow: number = 0;
    ticketnbreall: number = 0;
    ticketnbrenow: number = 0;
    ticketactive: number = 0;
    ticketexpire: number = 0;
    ticket: Ticket[];
    achat: Transaction[];

}


export class StatGlobalDto {
    eventnbreall: number;
    eventnbreinrunning: number;
    eventnbreinwaiting: number;
    eventnbreinclose: number;
    transactionnbreall: number;
    transactionsumall: number;
    transactionnbrenow: number;
    transactionsumnow: number;
    ticketnbreall: number;
    ticketnbrenow: number;
    ticketactive: number;
    ticketexpire: number;
    clientall: number;
    clientnow: number;
    carteall: number;
    cartenow: number;
    carteactive: number;
    carteblock: number;
}


export class Ticket {
    ticketid: string;
    ticketref: string;
    ticketdatecreation: Date;
    ticketpromoenable: number;
    ticketredval: number;
    ticketredpourc: number;
    ticketredmont: number;
    tickettarifavred: number;
    tickettarif: number;
    ticketbeneficiaire: string;
    ticketcodeachat: string;
    ticketevent: string;
    ticketeventid: string;
    ticketstatus: string;
    ticketenable: number;
    spectateur: Spectateur;
    caticket: Caticket;
    ticketmodel: string;
    spectateuremail: string;
    spectateurnom: string;
    spectateurprenom: string;
    spectateurtel: string;
    ticketetat: any;
    ticketplacevalue: any;
    ticketdebutval: any;
    ticketprovenanceachat: any;
    ticketvendeur: any;
    ticketvendeurtype: any;

}

export class Spectateur {
    lockFlag: number;
    spectateurid: string;
    spectateurcodecarte: string;
    spectateuremail: string;
    spectateurpassword: string;
    spectateurnom: string;
    spectateurprenom: string;
    spectateurdate: Date;
    spectateurenable: number;
    spectateurtel: string;
}

export class Transaction {
    lockFlag: number;
    transactionid: string;
    transactionref: string;
    transactiontotalavred: number;
    transactiontotalapred: number;
    transactionprixunitaire: number;
    transactionqte: number;
    transactioncodepromoenable: number;
    transactionnbrepromoenable: number;
    transactionpromomontantred: number;
    transactionnbrepromomontantred: number;
    transactioneventid: string;
    transactionevent: string;
    transactionorgid: string;
    transactionorg: string;
    transactioncaticketid: string;
    transactioncaticket: string;
    transactionspectateurid: string;
    transactionspectateurcarte: string;
    transactionspectateuremail: string;
    transactionspectateurname: string;
    transactionenable: number;
    transactiondate: any;
    transactionprovenance: any;
    transactionprovenancetype: any;

}

export class ticketvip {
    cardvalue: string;
    cateventid: string;
    checkspectateur: boolean;
    spectateur: Spectateur;
    statecard: string;
    ticketvendeurid: string;
    typeticket: string;
}



export class Evenment {

    categorie: string;
    categoriecode: string;
    eventcode: string;
    eventcreerpar: string;
    eventdatedisable: string;
    eventdebut: string;
    eventdebutcheck: string;
    eventdebutvente: string;
    eventdesclevel1: string;
    eventdesclevel2: string;
    eventetat: string;
    eventfin: string;
    eventfincheck: string;
    eventfinvente: string;
    eventgeo: string;
    eventicketpers: number;
    eventinvite: string;
    eventitre: string;
    eventlieu: string;
    eventmaxpart: number;
    eventmaxticket: number;
    eventorg: string;
    eventpart: string;
    eventstatus: string;
    isadmin: boolean;
    organe: string;
    organecode: string;
    typevent: string;
    typeventcode: string;
}




export class checkPayment {
    checkPromoCode: boolean;
    checkPromoQte: boolean;
    cumul: number;
    isAvailable: boolean;
    reduction: number;
    total: number;
    nbreticket: number;
    qrcode: number;
    carte: number;

}




export class Item {
    carte: string;
    email: string;
    nom: string;
    numero: number;
    prenom: string;
    telephone: string;
    type: string;
}


export class CheckLevel2 {
    caticketcode: string;
    checkcode: boolean;
    eventcode: string;
    items: Item[];
    montant: number;
    reduction: string;
    usercode: string;
    web: boolean;
}


export class statDto {
    caticketcode: string;
    usercode: string;
    checkevent: boolean;
    debut: string;
    eventcode: string;
    fin: string;
}


export class Achat {
    achatcode: string;
    achatdatecreation: Date;
    achatdatelevel1: Date;
    achatdatelevel2: Date;
    achatlevel: number;
    achatmontantnormal: number;
    achatmontantpayer: number;
    achatnbrecarte: number;
    achatnbreqr: number;
    achatnbreticket: number;
    achatreduction: number;
    acheteurcode: string;
    acheteuremail: string;
    acheteurnom: string;
    acheteurprenom: string;
    acheteurtel: string;
    caticket: string;
    caticketcode: string;
    cel_phone_num: string;
    cpm_trans_id: string;
    event: string;
    eventcode: string;
    organe: string;
    organecode: string;
    payment_method: string;
    provenance: string;
    provenancecode: string;
    signature: string;
    spectateurcode: string;
    typeacheteur: string;
    typeacheteurcode: string;
}


export class TicketStat {
    achatcode: string;
    beneficiairecarte: string;
    beneficiairenom: string;
    beneficiaireprenom: string;
    beneficiairetel: string;
    caticket: string;
    caticketcode: string;
    ctrl: string;
    ctrlcode: string;
    event: string;
    eventcode: string;
    hasreduction: boolean;
    lockFlag: number;
    mode: string;
    modecode: string;
    organe: string;
    organecode: string;
    provenance: string;
    provenancecode: string;
    spectateurcode: string;
    spectateuremail: string;
    spectateurnom: string;
    spectateurprenom: string;
    spectateurtel: string;
    spectateurtype: string;
    spectateurtypecode: string;
    ticketcode: string;
    ticketdatecheckend: Date;
    ticketdatecheckstart: Date;
    ticketdatecreation: Date;
    ticketenable: boolean;
    ticketmontantnormal: number;
    ticketmontantpayer: number;
    ticketreduction: number;
    ticketstep: number;
    typeticket: string;
    typeticketcode: string;
}
