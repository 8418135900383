import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [

      { path: '', redirectTo: '/session/connexion', pathMatch: 'full' },

      //{ path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'org/dashboard',
        loadChildren: () =>
          import('./billeterie/organisateur/dashbord/dashbord.module').then((m) => m.DashbordModule)
      },

      {
        path: 'admin/event',
        loadChildren: () =>
          import('./billeterie/organisateur/event/event.module').then((m) => m.EventModule)
      },

      {
        path: 'admin/user',
        loadChildren: () =>
          import('./billeterie/organisateur/accountuser/accountuser.module').then((m) => m.AccountuserModule)
      },

      {
        path: 'admin/account',
        loadChildren: () =>
          import('./billeterie/organisateur/account/account.module').then((m) => m.AccountModule)
      },{
        path: 'admin/account-admin',
        loadChildren: () =>
          import('./billeterie/organisateur/account-admin/account-admin.module').then((m) => m.AccountAdminModule)
      },
      {
        path: 'admin/checkeventseller',
        loadChildren: () =>
          import('./billeterie/organisateur/accountevent/accountevent.module').then((m) => m.AccounteventModule)
      },

      {
        path: 'admin/image',
        loadChildren: () =>
          import('./billeterie/organisateur/image/image.module').then((m) => m.ImageModule)
      },


      {
        path: 'admin/caticket',
        loadChildren: () =>
          import('./billeterie/organisateur/caticket/caticket.module').then((m) => m.CaticketModule)
      },
      {
        path: 'admin/promo',
        loadChildren: () =>
          import('./billeterie/organisateur/promo/promo.module').then((m) => m.PromoModule)
      },
      {
        path: 'admin/carte',
        loadChildren: () =>
          import('./billeterie/organisateur/carte/carte.module').then((m) => m.CarteModule)
      },
      {
        path: 'admin/cartes',
        loadChildren: () =>
          import('./billeterie/organisateur/mes-cartes/mes-carte.module').then((m) => m.MesCarteModule)
      },
      {
        path: 'admin/sms',
        loadChildren: () =>
          import('./billeterie/organisateur/notif/notif.module').then((m) => m.NotifModule)
      },

      {
        path: 'org/notification',
        loadChildren: () =>
          import('./billeterie/organisateur/commentaire/commentaire.module').then((m) => m.CommentaireModule)
      },


      {
        path: 'admin/organisateur',
        loadChildren: () =>
          import('./billeterie/organ/organisateur.module').then((m) => m.OrganisateurModule)
      },{
        path: 'admin/spectateur',
        loadChildren: () =>
          import('./billeterie/spectateur/spectateur.module').then((m) => m.SpectateurModule)
      },

      {
        path: 'admin/profil',
        loadChildren: () =>
          import('./billeterie/organisateur/profil/profil.module').then((m) => m.ProfilModule)
      },


      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'advance-table',
        loadChildren: () =>
          import('./advance-table/advance-table.module').then(
            (m) => m.AdvanceTableModule
          )
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./calendar/calendar.module').then((m) => m.CalendarsModule)
      },
      {
        path: 'task',
        loadChildren: () =>
          import('./task/task.module').then((m) => m.TaskModule)
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('./contacts/contacts.module').then((m) => m.ContactsModule)
      },
      {
        path: 'email',
        loadChildren: () =>
          import('./email/email.module').then((m) => m.EmailModule)
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./apps/apps.module').then((m) => m.AppsModule)
      },
      {
        path: 'widget',
        loadChildren: () =>
          import('./widget/widget.module').then((m) => m.WidgetModule)
      },
      {
        path: 'ui',
        loadChildren: () => import('./ui/ui.module').then((m) => m.UiModule)
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./forms/forms.module').then((m) => m.FormModule)
      },
      {
        path: 'tables',
        loadChildren: () =>
          import('./tables/tables.module').then((m) => m.TablesModule)
      },
      {
        path: 'media',
        loadChildren: () =>
          import('./media/media.module').then((m) => m.MediaModule)
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./charts/charts.module').then((m) => m.ChartsModule)
      },
      {
        path: 'timeline',
        loadChildren: () =>
          import('./timeline/timeline.module').then((m) => m.TimelineModule)
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./icons/icons.module').then((m) => m.IconsModule)
      },
      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          )
      },
      {
        path: 'maps',
        loadChildren: () =>
          import('./maps/maps.module').then((m) => m.MapsModule)
      },
      {
        path: 'multilevel',
        loadChildren: () =>
          import('./multilevel/multilevel.module').then(
            (m) => m.MultilevelModule
          )
      }
    ]
  },
  {
    path: 'session',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./billeterie/authorization/authorization.module').then((m) => m.AuthorizationModule
      )
  },

  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },

  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
