import {RouteInfo} from './sidebar.metadata';

export const ORGROUTES: RouteInfo[] = [


  {
    path: 'admin/event',
    title: 'Evenement',
    moduleName: 'event',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Mes cartes',
    moduleName: 'carte',
    icon: 'credit-card',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/admin/carte/categorie',
        title: 'Categories carte',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/admin/carte/all',
        title: 'CARTE',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/admin/carte/generer',
        title: 'Generer carte',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/admin/carte/groupe',
        title: 'Groupe carte',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/admin/carte/vente',
        title: 'Cartes vendues',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Gérer mes cartes',
    moduleName: 'cartee',
    icon: 'credit-card',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/admin/cartes/liste-carte',
        title: 'Carte',
        moduleName: 'cartee',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/admin/cartes/reference-carte',
        title: 'Référence carte',
        moduleName: 'cartee',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/admin/cartes/genere-carte',
        title: 'Générer carte',
        moduleName: 'cartee',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/admin/cartes/groupes',
        title: 'Groupe carte',
        moduleName: 'cartee',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'utilisateurs',
    moduleName: 'account',
    icon: 'user-check',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'admin/account',
        title: 'Compte utilisateur',
        moduleName: 'account',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'admin/account-admin',
        title: 'Compte admins',
        moduleName: 'account',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Organisateurs',
    moduleName: 'organisateur',
    icon: 'user',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'admin/organisateur/liste-organisateur',
        title: 'Liste',
        moduleName: 'cartee',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Spectateurs',
    moduleName: 'spectateur',
    icon: 'users',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'admin/spectateur/liste-spectateur',
        title: 'Liste',
        moduleName: 'cartee',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  }
  ,
  {
    path: 'admin/sms',
    title: 'Sms',
    moduleName: 'notification',
    icon: 'mail',
    class: '',
    groupTitle: false,
    submenu: []
  }


  /*
  {
      path: 'org/caticket',
      title: 'Categorie ticket',
      moduleName: 'caticket',
      icon: 'command',
      class: '',
      groupTitle: false,
      submenu: []
  },
  {
      path: 'org/promo',
      title: 'Promotion',
      moduleName: 'promo',
      icon: 'grid',
      class: '',
      groupTitle: false,
      submenu: []
  },

  {
      path: 'admin/account',
      title: 'Compte utilisateur',
      moduleName: 'account',
      icon: 'user-check',
      class: '',
      groupTitle: false,
      submenu: []
  }
  */

]


export const SELLROUTES: RouteInfo[] = [


  {
    path: 'admin/event',
    title: 'Evenement',
    moduleName: 'event',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'admin/profil',
    title: 'Mon Profil',
    moduleName: 'account',
    icon: 'user-check',
    class: '',
    groupTitle: false,
    submenu: []
  }

]

export const CARTEROUTES: RouteInfo[] = [

  {
    path: '',
    title: 'MES CARTES',
    moduleName: 'carte',
    icon: 'credit-card',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [

      {
        path: '/admin/carte/all',
        title: 'CARTE',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },

      {
        path: '/admin/carte/vente',
        title: 'CARTE VENDUES',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'admin/profil',
    title: 'Mon Profil',
    moduleName: 'account',
    icon: 'user-check',
    class: '',
    groupTitle: false,
    submenu: []
  }

]


export const CARTESELLROUTES: RouteInfo[] = [


  {
    path: 'admin/event',
    title: 'Evenement',
    moduleName: 'event',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'MES CARTES',
    moduleName: 'carte',
    icon: 'credit-card',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [

      {
        path: '/admin/carte/all',
        title: 'CARTE',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },

      {
        path: '/admin/carte/vente',
        title: 'CARTE VENDUES',
        moduleName: 'carte',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'admin/profil',
    title: 'Mon Profil',
    moduleName: 'account',
    icon: 'user-check',
    class: '',
    groupTitle: false,
    submenu: []
  }

]


