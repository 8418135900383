<div class="container">
    <h3 mat-dialog-title>{{data.title}}</h3>
    <div mat-dialog-content>
        <ul class="clearfix">
            <li>
                <p><span class="font-weight-bold"> </span>{{data.message}}</p>
            </li>
        </ul>
    </div>
    <div mat-dialog-actions class="mb-1">
        <button mat-flat-button color="warn" (click)="confirmDelete()">
            VALIDER
        </button>
        <button mat-flat-button (click)="onNoClick()" tabindex="-1">Annuler</button>
    </div>
</div>