import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { Userstorage } from '../../billeterie/model/entity';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
    public USER_CONFIG: any;
    constructor(
        public router: Router,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // @ts-ignore
        let u = new Userstorage();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        const token: string = u?.token;
        req = req.clone({ headers: req.headers.set('Authorization', token ? token : '') });
        return next.handle(req).pipe(
            catchError((error) => {
                let handled: boolean = false;
                if (error instanceof HttpErrorResponse) {
                    if (error.error instanceof ErrorEvent) {
                    } else {
                        console.error(error.status)
                    }
                }
                else { }
                if (handled) {
                    return of(error);
                } else {
                    return throwError(error);
                }

            })
        )
    }
}
